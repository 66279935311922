/* eslint-disable no-plusplus */
import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { AllowanceService } from 'services';
import { action } from 'mobx';

import {
  Grid, Typography, Button, TableContainer, Table, TableRow, TableHead, TableBody, TableCell
} from '@material-ui/core';

import {
  faArrowLeft, faArrowRight
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import { translate, BookingHelper } from 'utils';
import { ALLOWANCE_LEISURE_ADVANTAGE_TYPE } from 'utils/constants';
import { useStores } from 'hooks';
import shortid from 'shortid';
import { Datepicker } from '../../../_commons';

const CustomTableCell = styled(TableCell)`
  font-size: 20px;
  &[data-selected="true"]{
    background-color: var(--secondary-color);
    color: var(--white);
  }

  &[data-na="true"]{
    background-color: var(--grey-darker);
    color: var(--white);
  }
`;

const CustomTableContainer = styled(TableContainer)`
  border: 2px solid;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const Arrow = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const PriceContainer = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const BookingByWeek = ({
  price, setDateRange, setPrice, allowance,
  userTariffRange, setInterEstablishmentID,
  tTError, setTTError, userAllowanceApplicationDetail, dateRange
}) => {
  // eslint-disable-next-line no-nested-ternary
  const getInitialBookingDate = () => {
    if (userAllowanceApplicationDetail) {
      return new Date(userAllowanceApplicationDetail.startDate);
    }
    if (dateRange) {
      return dateRange[0];
    }
    return new Date();
  };

  const [bookingDate, setBookingDate] = useState(getInitialBookingDate());
  const [bookingArray, setBookingArray] = useState([['Répartions']]);
  const { advantageTypes } = allowance;
  const { allowanceStore } = useStores();
  const { allowanceHighSeasons } = allowanceStore;

  const handleParseDate = useCallback(date => format(new Date(date), 'MMMM yyyy', { locale: frLocale }), []);

  const [selectedItemCoordinates, setSelectedItemCoordinates] = useState([]);
  const [allBookingList, setAllBookingList] = useState([]);
  const [bookingList, setBookingList] = useState([]);

  const createBookingArray = useCallback(bookingList => {
    if (bookingList) {
      const tmpDateRangeList = [];
      const tmpCapacityList = [];
      bookingList.forEach(b => {
        const range = `${b.dateBegin}-${b.dateEnd}`;
        if (!tmpDateRangeList.includes(range)) {
          tmpDateRangeList.push(range);
        }
        
        if (!tmpCapacityList.includes(b.capacity)) {
          tmpCapacityList.push(b.capacity);
        }
      });
      const tmpArray = [['Répartitions']];
      tmpDateRangeList.forEach(range => {
        tmpArray[0].push(range);
      });
      tmpCapacityList.forEach(capacity => {
        tmpArray.push([capacity]);
      });

      for (let i = 1; i < tmpArray.length; i++) {
        for (let j = 1; j < tmpArray[0].length; j++) {
          if (!tmpArray[i][j]) {
            tmpArray[i][j] = bookingList.find(booking => booking.capacity === tmpArray[i][0] && `${booking.dateBegin}-${booking.dateEnd}` === tmpArray[0][j]);
          }
        }
      }

      setBookingArray(tmpArray);
    }
  }, []);

  function extractRequestedAvailabilities(booking, internalId) {
    if (booking.interEstablishmentId === undefined) {
      return undefined;
    }

    const item = {};
    booking.internalId = internalId;
    item.interEstablishmentId = booking.interEstablishmentId;
    item.internalId = booking.internalId;
    item.startDate = booking.dateBegin;
    item.endDate = booking.dateEnd;
    return item;
  }

  useEffect(() => {
    let bookingWithoutAvailability = bookingList.filter(booking => booking.availability === undefined)
    if (userAllowanceApplicationDetail) {
      for (const booking of bookingWithoutAvailability) {
        booking.availability = true;
      }

      createBookingArray(bookingList);
      return;
    }

    let internalId = 0;
    let requestedAvailabilities = [];
    for (const booking of bookingWithoutAvailability) {
        requestedAvailabilities.push(extractRequestedAvailabilities(booking, ++internalId));
    }

    requestedAvailabilities = requestedAvailabilities.filter(item => item !== undefined)

    if (requestedAvailabilities.length === 0) {
      createBookingArray(bookingList);
      return;
    }

    AllowanceService.getAvailabilities(requestedAvailabilities).then(action(result => {
      for (const booking of bookingWithoutAvailability) {
        updateAvailability(booking, result);
      }

      createBookingArray(bookingList);
    }))
    .catch(error => {
      createBookingArray(bookingList);
    });
  }
  , [bookingList, createBookingArray, userAllowanceApplicationDetail]);

  function updateAvailability(booking, availabilities) {
    const availability = availabilities.find(obj => obj.internalId === booking.internalId);
    if (availability !== undefined) {
      booking.availability = availability.isAvailable;
    }
  }

  const getBookingForCurrentMonth = useCallback(bookingList => {
    const currentMonth = bookingDate.getMonth();
    const currentYear = bookingDate.getFullYear();
    const today = new Date();
    let filteredBooking = bookingList.filter(booking => (new Date(booking.dateBegin).getMonth() === currentMonth || new Date(booking.dateEnd).getMonth() === currentMonth)
      && (new Date(booking.dateBegin).getFullYear() === currentYear || new Date(booking.dateEnd).getFullYear() === currentYear)
      && new Date(booking.dateBegin) >= today);    
    return filteredBooking
  }, [bookingDate]);

  useEffect(() => {
    setAllBookingList([]);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAllBookingList(BookingHelper.getFormattedBookingByWeek(allowance, userTariffRange));
  }, [allowance, userTariffRange]);

  useEffect(() => 
  {
    setBookingList(getBookingForCurrentMonth(allBookingList));
  }, [allBookingList, getBookingForCurrentMonth]);

  useEffect(() => {
    if ((userAllowanceApplicationDetail && dateRange)) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      bookingArray.forEach((row, rowIndex) => {
        row.forEach((item, itemIndex) => {
          if ((new Date(item.dateBegin).getDate() === startDate.getDate()
            && new Date(item.dateBegin).getMonth() === startDate.getMonth()
            && new Date(item.dateBegin).getFullYear() === startDate.getFullYear())
            && (new Date(item.dateEnd).getDate() === endDate.getDate()
            && new Date(item.dateEnd).getMonth() === endDate.getMonth()
            && new Date(item.dateEnd).getFullYear() === endDate.getFullYear())
            && item.capacity === userAllowanceApplicationDetail.interEstablishmentAllowance.unitCapacity) {
            setSelectedItemCoordinates({ x: rowIndex, y: itemIndex });
            if (BookingHelper.checkIfHighSeason(allowanceHighSeasons, new Date(item.dateBegin))) {
              setPrice(item.priceHighSeason);
            } else {
              setPrice(item.price);
            }
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [bookingArray, userAllowanceApplicationDetail, setPrice]);

  useEffect(() => {
    for (let i = 1; i < bookingArray.length; i++) {
      bookingArray[i].forEach(item => {
        if (item?.price === 'NA') {
          setTTError(true);
        }
      });
      if (tTError) {
        break;
      }
    }
  }, [bookingArray, setTTError, tTError]);

  const getInterEstablishmentID = item => (
    allowance.interEstablishments.find(interEstablishment => item.name === interEstablishment.name).id
  );

  const TableSubContainer = ({ header, children }) => (
    header
      ? (
        <TableHead>
          {children}
        </TableHead>
      )
      : (
        <TableBody>
          {children}
        </TableBody>
      )
  );

  const itemFormatting = (rowIndex, item) => {
    if (item) {
      if (item.price != null) {
        if (!item.availability) {
          return `Indisponible`
        }

        if (advantageTypes[0].id === ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.code || item.price === 0) {
          return ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.label;
        }

        if (item.price === 'NA') {
          return `${item.price}`;
        }

        if (BookingHelper.checkIfHighSeason(allowanceHighSeasons, new Date(item.dateBegin))) {
          return `${item.priceHighSeason}€`;
        }
        return `${item.price}€`;
      }
      if (rowIndex === 0) {
        if (item.indexOf('/') !== -1) {
          const dateElements = item.split('-');
          const dateBeginElements = dateElements[0].split('/');
          const dateEndElements = dateElements[1].split('/');
          return `Du ${dateBeginElements[1]}/${dateBeginElements[0]}/${dateBeginElements[2]} au ${dateEndElements[1]}/${dateEndElements[0]}/${dateEndElements[2]}`;
        }

        return item;
      }

      return `1x${item} couchages`;
    }
    return '';
  };

  const handleOnSelect = (item, rowIndex, itemIndex) => {
    setInterEstablishmentID(getInterEstablishmentID(item));
    setSelectedItemCoordinates({ x: rowIndex, y: itemIndex });
    if (BookingHelper.checkIfHighSeason(allowanceHighSeasons, new Date(item.dateBegin))) {
      setPrice(item.priceHighSeason);
    } else {
      setPrice(item.price);
    }

    const newDateRange = [new Date(item.dateBegin), new Date(item.dateEnd)];
    setDateRange(newDateRange);
  };

  const checkIfSelected = (x, y) => {
    if (!selectedItemCoordinates) {
      return false;
    }

    return Boolean(x === selectedItemCoordinates.x && y === selectedItemCoordinates.y);
  };

  const resetData = useCallback(() => {
    setSelectedItemCoordinates([]);
    setPrice(0);
  }, [setPrice]);

  const addOneMonth = useCallback(() => {
    resetData();
    setBookingDate(new Date(bookingDate.setMonth(bookingDate.getMonth() + 1)));
  }, [bookingDate, resetData]);

  const removeOneMonth = useCallback(() => {
    resetData();
    setBookingDate(new Date(bookingDate.setMonth(bookingDate.getMonth() - 1)));
  }, [bookingDate, resetData]);

  const arrowMove = useCallback(e => {
    e.stopPropagation();
    const today = new Date();
    if (e.code === 'ArrowLeft') {
      if (bookingDate.getFullYear() === today.getFullYear()) {
        if (bookingDate.getMonth() > today.getMonth()) {
          removeOneMonth();
        }
      } else {
        removeOneMonth();
      }
    }

    if (e.code === 'ArrowRight') {
      addOneMonth();
    }
    // eslint-disable-next-line
  }, []);

  document.addEventListener('keydown', arrowMove);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid alignItems="flex-end" container direction="row" spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography>{translate('pageBookingByWeek.selectMonth')}</Typography>

          <Datepicker
            disablePast
            label=""
            labelFunc={handleParseDate}
            leftArrowIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            minDate={new Date()}
            openTo="month"
            required
            value={bookingDate}
            views={['year', 'month']}
            onChange={date => setBookingDate(date)}
          />
        </Grid>

        {!((bookingDate.getMonth() === new Date().getMonth()) && (bookingDate.getFullYear() === new Date().getFullYear())) && (
          <Grid item>
            <Arrow icon={faArrowLeft} onClick={removeOneMonth} />
          </Grid>
        )}

        <Grid item>
          <Arrow icon={faArrowRight} onClick={addOneMonth} />
        </Grid>
      </Grid>

      {bookingArray.length > 1 ? (
        <CustomTableContainer>
          <Table size="small">
            {bookingArray.map((row, rowIndex) => (
              <TableSubContainer header={rowIndex === 0} key={shortid.generate()}>
                <TableRow>
                  {row.map((item, itemIndex) => (
                    <CustomTableCell
                      align="center"
                      data-na={item.price === 'NA' || (item.price >= 0 && !item.availability)}
                      data-selected={checkIfSelected(rowIndex, itemIndex)}
                      key={shortid.generate()}
                    >
                      {itemFormatting(rowIndex, item)}
                      {(item && (item.price >= 0) && !checkIfSelected(rowIndex, itemIndex) && (item.availability)) && (
                        <Grid item>
                          <Button
                            color="secondary"
                            disabled={!!userAllowanceApplicationDetail}
                            onClick={() => handleOnSelect(item, rowIndex, itemIndex)}
                          >
                            {translate('pageBookingByWeek.book')}
                          </Button>
                        </Grid>
                      )}
                    </CustomTableCell>
                  ))}
                </TableRow>
              </TableSubContainer>
            ))}
          </Table>
        </CustomTableContainer>
      ) : <Grid item><div>{translate('pageBookingByWeek.nobookingAvailable')}</div></Grid>}

      <Grid item sm={12} xs={12}>
        <PriceContainer>
          {advantageTypes[0].id === ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.code
            ? `Prix : ${ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.label}`
            : `Prix : ${userAllowanceApplicationDetail ? userAllowanceApplicationDetail.orderAmount : price}€`}
        </PriceContainer>
      </Grid>
      {tTError && (
        <Grid item sm={12} xs={12}>
          <Typography color="error">
            NA: Pas de prix défini pour votre tranche tarifaire, veuillez contacter l&apos;ADOSSPP
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default BookingByWeek;