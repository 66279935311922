import {
  faBan,
  faBars, faChild, faEnvelopeSquare, faEuroSign, faFootballBall, faHandHoldingHeart, faHandHoldingSeedling,
  faHandsHeart, faHome, faHouseNight, faPlane, faShareSquare,
  faTasks, faTheaterMasks, faTimes, faUserCheck, faUserLock, faVoteYea
} from '@fortawesome/pro-regular-svg-icons';

// eslint-disable-next-line no-underscore-dangle
const getEnv = key => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_URL = getEnv('API_URL');
export const API_URL_SECURITY = getEnv('API_URL_SECURITY');
export const API_URL_PAYBOX = getEnv('PAYBOX_HMAC');
export const API_URL_INTRASSOC = getEnv('API_URL_INTRASSOC');
export const API_KEY = getEnv('API_KEY');
export const PAYBOX_URL = getEnv('PAYBOX_URL');
export const FRANCEBILLET = getEnv('FRANCEBILLET');
export const FRANCEBILLET_URL = getEnv('FRANCEBILLET_URL');
export const FRANCEBILLET_MODE = getEnv('FRANCEBILLET_MODE');

export const GLADY_URL = getEnv('GLADY_URL');
export const GLADY_API_TOKEN = getEnv('GLADY_API_TOKEN');
export const GLADY_API_CLIENT_ID = getEnv('GLADY_API_CLIENT_ID');
export const GLADY_API_CLIENT_SECRET = getEnv('GLADY_API_CLIENT_SECRET');

export const APP_VERSION = getEnv('VERSION');

export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');

export const FRANCEBILLET_SUFFIX = memberCode => `/servlets/autologon?codece=ADOSSPP&id=${memberCode}`;

export const MISTERFLY_URL = getEnv('MISTERFLY_URL');

export const PATHS = {
  HOME: `${ROOT_PATH}accueil`,
  CONNECT: `${ROOT_PATH}connexion`,
  CONTACT: `${ROOT_PATH}contact`,
  MENTIONS: `${ROOT_PATH}mentions-legales`,

  ALLOWANCE_SOCIAL_MAIN: `${ROOT_PATH}prestations-sociales`,
  ALLOWANCE_SOCIAL_CATEGORY: `${ROOT_PATH}prestations-sociales/:category`,
  ALLOWANCE_SOCIAL_DETAIL: `${ROOT_PATH}prestations-sociales/:category/:id`,
  ALLOWANCE_SOCIAL_DETAIL_CONFIRM: `${ROOT_PATH}prestations-sociales/:category/:id/confirmation`,

  ALLOWANCE_LEISURE_MAIN: `${ROOT_PATH}prestations-loisirs`,
  ALLOWANCE_LEISURE_CATEGORY: `${ROOT_PATH}prestations-loisirs/:category`,
  ALLOWANCE_LEISURE_DETAIL: `${ROOT_PATH}prestations-loisirs/:category/:id`,
  ALLOWANCE_LEISURE_DETAIL_BOOKING: `${ROOT_PATH}prestations-loisirs/:category/:id/reservation`,
  ALLOWANCE_LEISURE_DETAIL_CONFIRM: `${ROOT_PATH}prestations-loisirs/:category/:id/confirmation`,

  PAYBOX_CONFIRM: `${ROOT_PATH}paybox/confirmation`,

  PAYBOX_CONFIRM_SUBSCRIPTION: `${ROOT_PATH}paybox/confirmation/subscription`,

  USER_PROFILE: `${ROOT_PATH}profil`,
  USER_ALLOWANCE_APPLICATION: `${ROOT_PATH}profil/demandes-de-prestations`,

  SUBSCRIPTION: `${ROOT_PATH}subscription`,
  SUBSCRIPTION_DESCRIPTION: `${ROOT_PATH}subscription/description`,
  CONFIRM_SUBSCRIPTION: `${ROOT_PATH}subscription/confirmation`
};

export const ROUTES = {
  ...PATHS,
  ALLOWANCE_SOCIAL_CATEGORY: category => `${ROOT_PATH}prestations-sociales/${category}`,
  ALLOWANCE_SOCIAL_DETAIL: (category, allowance) => `${ROOT_PATH}prestations-sociales/${category}/${allowance}`,
  ALLOWANCE_SOCIAL_DETAIL_CONFIRM: (category, allowance) => (
    `${ROOT_PATH}prestations-sociales/${category}/${allowance}/confirmation`
  ),
  ALLOWANCE_LEISURE_CATEGORY: category => `${ROOT_PATH}prestations-loisirs/${category}`,
  ALLOWANCE_LEISURE_DETAIL: (category, allowance) => `${ROOT_PATH}prestations-loisirs/${category}/${allowance}`,
  ALLOWANCE_LEISURE_DETAIL_BOOKING: (category, allowance) => (
    `${ROOT_PATH}prestations-loisirs/${category}/${allowance}/reservation`
  ),
  ALLOWANCE_LEISURE_DETAIL_CONFIRM: (category, allowance) => (
    `${ROOT_PATH}prestations-loisirs/${category}/${allowance}/confirmation`
  ),
  PAYBOX_CONFIRM: params => (
    `${ROOT_PATH}paybox/confirmation?${params}`
  ),
  PAYBOX_CONFIRM_SUBSCRIPTION: params => (
    `${ROOT_PATH}paybox/confirmation/subscription?${params}`
  )
};

export const API_ROUTES = {
  ALLOWANCES: () => `${API_URL}/Allowance`,
  ALLOWANCES_TYPE_CATEGORY: (typeId, categoryId) => (
    `${API_URL}/Allowance/ByTypeAndCategory?typeId=${typeId}&categoryId=${categoryId}`
  ),
  ALLOWANCE_DETAIL: id => `${API_URL}/Allowance/${id}`,
  ALLOWANCE_CATEGORIES: typeId => `${API_URL}/Allowance/Categories/${typeId}`,
  ALLOWANCE_SUB_CATEGORIES: categoryId => `${API_URL}/Allowance/subCategories/${categoryId}`,
  ALLOWANCE_PAYMENT_MEANS: typeId => `${API_URL}/Allowance/AmountCalculatingMeans/${typeId}`,
  ALLOWANCE_HIGH_SEASONS: () => `${API_URL}/Allowance/highSeason`,
  ALLOWANCE_MODULES: () => `${API_URL}/Allowance/Modules`,
  ALLOWANCE_PAYMENT_MODES: () => `${API_URL}/Allowance/PaymentMethods`,
  ALLOWANCE_TARIFF_SECTIONS: () => `${API_URL}/Allowance/tariffSections`,
  ALLOWANCE_AVAILABILITIES: () => `${API_URL}/Allowance/interEstablishmentsAvailabilities`,
  ALLOWANCE_AVAILABILITIES_BY_HALF_DAY: () => `${API_URL}/Allowance/interEstablishmentsAvailabilitiesByHalfDay`,

  NEWS_LIST: () => `${API_URL_SECURITY}/Alert`,
  NEWS_DETAIL: news => `${API_URL_SECURITY}/News/${news}`,

  RIGHT_HOLDER: () => `${API_URL}/RightHolder`,
  RIGHT_HOLDER_DETAIL: id => `${API_URL}/RightHolder/${id}`,

  USER_ALLOWANCE_APPLICATION: () => `${API_URL}/AllowanceApplication`,
  USER_ALLOWANCE_APPLICATION_UPDATE_STATUS: () => `${API_URL}/AllowanceApplication/updateStatusRange`,
  USER_ALLOWANCE_APPLICATION_REWORK: () => `${API_URL}/AllowanceApplication/AllAppMemberArea`,
  USER_ALLOWANCE_APPLICATION_DETAIL: id => `${API_URL}/AllowanceApplication/${id}`,
  USER_ALLOWANCE_APPLICATION_TYPES: () => `${API_URL}/AllowanceApplication/Types`,

  USER_ALLOWANCE_APPLICATION_CEIL: allowanceId => `${API_URL}/AllowanceApplication/availableCeil?allowanceId=${allowanceId}`,
  USER_ALLOWANCE_APPLICATION_CSFC_RIGHT: () => `${API_URL}/AllowanceApplication/hasCsfcRight`,

  USER_MISTERFLY_HAS_ALREADY_BENEFITED: () => `${API_URL}/Misterfly/hasAlreadyBenefited`,

  PAYBOX_REDIRECTION: () => `${API_URL}/Paybox/`,

  USER_PROFILE: () => `${API_URL_SECURITY}/User`,
  GET_NEW_PASSWORD: () => `${API_URL_SECURITY}/User/newPassword`,
  LOGIN: () => `${API_URL_SECURITY}/Login`,
  LOGIN_REFRESH: () => `${API_URL_SECURITY}/Login/refreshToken`,
  FORGOT_PASSWORD: () => `${API_URL_SECURITY}/Login/forgotPassword`,
  FRANCE_BILLET_ACCESS_URL: () => `${API_URL_SECURITY}/franceBillet`,
  GLADY_ACCESS_URL: () => `${API_URL_SECURITY}/glady`,
  MISTERFLY_MEMBER_TOKEN: () => `${API_URL_SECURITY}/MisterFly/memberToken`,

  CITY_BY_POSTAL_CODE: postalCode => `https://geo.api.gouv.fr/communes?codePostal=${postalCode}&fields=code,nom`,

  HEALTH_CHECK: `${API_URL}/health`,
  PING: `${API_URL}/ping`,

  MESSAGES: `${API_URL}/Messages`,

  PAYBOX: `${API_URL_PAYBOX}`,

  ALLOWANCE_PAYMENT: `${API_URL_INTRASSOC}/api/reglement`,

  FRANCEBILLET: `${FRANCEBILLET}`,

  GLADY_URL: `${GLADY_URL}`,
  GLADY_API_TOKEN: `${GLADY_API_TOKEN}`,
  GLADY_API_CLIENT_ID: `${GLADY_API_CLIENT_ID}`,
  GLADY_API_CLIENT_SECRET: `${GLADY_API_CLIENT_SECRET}`,

  USER_SUBSCRIPTIONS: `${API_URL_INTRASSOC}/api/Abonnement`,
  UPDATE_ADDRESS_SUBSCRIPTIONS: `${API_URL_INTRASSOC}/api/Abonnement/adresse`,
  USER_SUBSCRIPTIONS_CREATE: `${API_URL_INTRASSOC}/api/Abonnement`,
  USER_SUBSCRIPTIONS_RENEWAL: idSubscription => `${API_URL_INTRASSOC}/api/Abonnement/${idSubscription}`,
  USER_SUBSCRIPTIONS_PRICES: `${API_URL_INTRASSOC}/api/Abonnement/tarifsAbonnement`,
  USER_SUBSCRIBER_CREATE: `${API_URL_INTRASSOC}/api/Abonnement/ajouterAbonne`,
  USER_SUBSCRIBER_EXISTS: `${API_URL_INTRASSOC}/api/Abonnement/abonneExiste`,
  NUMBER_REVUE: `${API_URL_INTRASSOC}/api/Abonnement/numerosRevues`,

  USER_MEMBERSHIP_TARIFF: `${API_URL_INTRASSOC}/api/adherents/tarifs`,
  USER_MEMBERSHIP_CREATE: `${API_URL_INTRASSOC}/api/adhesion/currentSeason`,
  CIVILITIES: `${API_URL_INTRASSOC}/api/adherents/civilites`
};

export const MENU_ITEMS = [
  {
    label: 'menu.home',
    icon: faHome,
    url: PATHS.HOME,
    dataCy: 'headerMenuHome'
  }, {
    label: 'menu.allowanceSocial',
    icon: faHandsHeart,
    dataCy: 'headerMenuAllowanceSocial',
    url: PATHS.ALLOWANCE_SOCIAL_MAIN,
    privileges: ['EspaceAdherentVoirListePrestationsSociales']
  },
  {
    label: 'menu.allowanceLeisure',
    icon: faFootballBall,
    dataCy: 'headerMenuAllowanceLeisure',
    url: PATHS.ALLOWANCE_LEISURE_MAIN,
    privileges: ['EspaceAdherentVoirListePrestationsLoisirs']
  }
];

export const USER_MENU_ITEMS = [
  {
    label: 'userMenu.personalAllowances',
    icon: faHandHoldingHeart,
    url: PATHS.USER_ALLOWANCE_APPLICATION,
    dataCy: 'headerUserMenuPersonalAllowances',
    privileges: ['EspaceAdherentVoirDemandesPrestations']
  },
  {
    label: 'userMenu.personalData',
    icon: faUserLock,
    dataCy: 'headerUserMenuPersonalData',
    url: PATHS.USER_PROFILE
  }
];

export const SUB_MENU_ITEMS_SOCIAL = [
  {
    label: 'submenu.life',
    labelMobile: 'submenu.lifeMobile',
    icon: faHandHoldingSeedling,
    url: ROUTES.ALLOWANCE_SOCIAL_CATEGORY('vie')
  }, {
    label: 'submenu.children',
    labelMobile: 'submenu.childrenMobile',
    icon: faChild,
    url: ROUTES.ALLOWANCE_SOCIAL_CATEGORY('enfant')
  }, {
    label: 'submenu.help',
    labelMobile: 'submenu.helpMobile',
    icon: faHandHoldingHeart,
    url: ROUTES.ALLOWANCE_SOCIAL_CATEGORY('aide')
  }, {
    label: 'submenu.orphans',
    labelMobile: 'submenu.orphansMobile',
    icon: faChild,
    url: ROUTES.ALLOWANCE_SOCIAL_CATEGORY('orphelins')
  }
];

export const SUB_MENU_ITEMS_LEISURE = [
  {
    label: 'submenu.company',
    labelMobile: 'submenu.companyMobile',
    icon: faHouseNight,
    url: ROUTES.ALLOWANCE_LEISURE_CATEGORY('etablissements')
  }, {
    label: 'submenu.childrens',
    labelMobile: 'submenu.childrensMobile',
    icon: faChild,
    url: ROUTES.ALLOWANCE_LEISURE_CATEGORY('enfants')
  }, {
    label: 'submenu.travel',
    labelMobile: 'submenu.travelMobile',
    icon: faPlane,
    url: ROUTES.ALLOWANCE_LEISURE_CATEGORY('voyages')
  }, {
    label: 'submenu.cultural',
    labelMobile: 'submenu.culturalMobile',
    icon: faTheaterMasks,
    url: ROUTES.ALLOWANCE_LEISURE_CATEGORY('sorties')
  }, {
    label: 'submenu.bonsPlans',
    labelMobile: 'submenu.bonsPlansMobile',
    icon: faBars,
    url: ROUTES.ALLOWANCE_LEISURE_CATEGORY('bonsPlans')
  }
];

export const SUB_MENU_MAPPER = {
  [PATHS.HOME]: null,
  [PATHS.ALLOWANCE_SOCIAL_MAIN]: SUB_MENU_ITEMS_SOCIAL,
  [PATHS.ALLOWANCE_LEISURE_MAIN]: SUB_MENU_ITEMS_LEISURE
};

export const ALLOWANCE_APPLICATION = {
  allowanceName: "BILLETS D'AVION"
};

export const ALLOWANCE_TYPES = {
  CAS: 'CAS',
  CSFC: 'CSFC'
};

export const ALLOWANCE_TYPE_IDS = {
  CAS: 1,
  CSFC: 2,
  RESCUE: 3
};

export const ALLOWANCE_LEISURE_NATURE = {
  INTERNAL: {
    code: 1,
    label: 'Prestation interne'
  },
  EXTERNAL_DOUBLE: {
    code: 2,
    label: 'Prestation externe avec double réservation'
  },
  EXTERNAL_SINGLE: {
    code: 3,
    label: 'Prestation externe avec réservation unique'
  },
  TICKETS: {
    code: 4,
    label: 'Billetterie ADOSSPP'
  },
  PROMOTIONAL: {
    code: 5,
    label: 'Code promo'
  }
};

export const PAYMENT_TYPE = {
  TICKETS: { label: 'Tickets', code: 0 },
  INTERN: { label: 'Intern', code: 1 },
  RESUBSCRIPTION: { label: 'Resubscription', code: 2 },
  READHESION: { label: 'Readhesion', code: 3 },
  SUBSCRIPTION: { label: 'Subscription', code: 4 }
};

export const ALLOWANCE_LEISURE_TARIFF_TYPE = {
  BY_WEEK_AND_TYPE: {
    code: 1,
    label: 'Par semaine et type de prestation'
  },
  BY_HALF_DAY: {
    code: 2,
    label: 'Par demi-journée'
  },
  BY_NIGHT: {
    code: 3,
    label: 'A la nuitée'
  }
};

export const ALLOWANCE_LEISURE_PAYMENT_TYPE = {
  UNIQUE: {
    code: 4,
    label: 'Unique'
  },
  BY_PRICING_RANGE: {
    code: 5,
    label: 'Par tranche tarifaire'
  }
};

export const ALLOWANCE_LEISURE_ADVANTAGE_TYPE = {
  REDUCTION: {
    code: 3,
    label: 'Prix réduit'
  },
  FREE: {
    code: 4,
    label: 'Gratuit'
  }
};

export const MODULES = {
  HOSPITAL: {
    code: 1
  },
  BENEFICIARY: {
    code: 2
  },
  SESSION: {
    code: 3
  }
};

export const ALLOWANCE_TYPE_MAPPER = {
  1: 'ALLOWANCE_SOCIAL_DETAIL',
  2: 'ALLOWANCE_LEISURE_DETAIL'
};

export const ALLOWANCE_CATEGORY_MAPPER = {
  1: 'vie',
  2: 'enfant',
  3: 'aide',
  4: 'etablissements',
  5: 'enfants',
  6: 'voyages',
  7: 'sorties',
  8: 'bonsPlans',
  9: 'rescue',
  10: 'orphans'
};

export const ALLOWANCE_CATEGORY_MAPPER_REVERSE = {
  vie: 1,
  enfant: 2,
  aide: 3,
  etablissements: 4,
  enfants: 5,
  voyages: 6,
  sorties: 7,
  bonsPlans: 8,
  rescue: 9,
  orphelins: 10
};

export const ALLOWANCE_CATEGORY_MENU_MAPPER = {
  vie: 'submenu.life',
  enfant: 'submenu.children',
  aide: 'submenu.help',
  etablissements: 'submenu.company',
  enfants: 'submenu.childrens',
  voyages: 'submenu.travel',
  sorties: 'submenu.cultural',
  bonsPlans: 'submenu.bonsPlans',
  orphans: 'submenu.orphans'
};

export const ALLOWANCE_CATEGORY_TITLE_MAPPER = {
  vie: 'pageAllowanceSocial.life',
  enfant: 'pageAllowanceSocial.children',
  aide: 'pageAllowanceSocial.help',
  etablissements: 'pageAllowanceLeisure.company',
  enfants: 'pageAllowanceLeisure.childrens',
  voyages: 'pageAllowanceLeisure.travel',
  sorties: 'pageAllowanceLeisure.cultural',
  bonsPlans: 'pageAllowanceLeisure.bonsPlans',
  orphelins: 'pageAllowanceSocial.orphans'
};

export const ALLOWANCE_CATEGORY_ICON_MAPPER = {
  1: faHandHoldingSeedling,
  2: faChild,
  3: faHandHoldingHeart,
  10: faChild,
  4: faHouseNight,
  5: faChild,
  6: faPlane,
  7: faTheaterMasks,
  8: faBars
};

export const ALLOWANCE_SUBCATEGORY_MAPPER = {
  vie: [],
  enfant: [],
  aide: [],
  orphans: [],
  etablissements: [
    'Résidence',
    'Restaurant',
    'Salle de réception'
  ],
  enfants: [
    'Billetterie',
    'Support scolaire',
    'Colonie de vacances',
    'Centre d’activités'
  ],
  voyages: [
    'Résidence',
    'Camping',
    'Village de vacances',
    'Voyagiste',
    'Boîtes cadeaux',
    'Parc de loisirs'
  ],
  sorties: [
    'Cinéma',
    'Bien-être',
    'Spectacle',
    'Boîtes cadeaux',
    'Parc de loisirs'
  ],
  bonsPlans: [
    'Transport',
    'Technologie',
    'Voyagiste',
    'Bons plans'
  ]
};

export const STATUS_ID = {
  SEND: 0,
  PENDING: 1,
  VALIDATED: 2,
  REFUSED: 3,
  RECEIVED: 4,
  CLOTURED: 5,
  PAYED: 6
};

export const STATUS_DISPLAY = {
  0: {
    color: 'var(--secondary-color-light)',
    icon: faShareSquare,
    label: 'status.send'
  },
  1: {
    color: 'var(--warning-color-dark)',
    icon: faTasks,
    label: 'status.pending'
  },
  2: {
    color: 'var(--success-color-dark)',
    icon: faVoteYea,
    label: 'status.validated'
  },
  3: {
    color: 'var(--error-color-dark)',
    icon: faTimes,
    label: 'status.refused'
  },
  4: {
    color: 'var(--success-color-dark)',
    icon: faEnvelopeSquare,
    label: 'status.received'
  },
  5: {
    color: 'var(--grey-darker)',
    icon: faUserCheck,
    label: 'status.clotured'
  },
  6: {
    color: 'var(--success-color-dark)',
    icon: faEuroSign,
    label: 'status.payed'
  },
  7: {
    color: 'var(--error-color-dark)',
    icon: faBan,
    label: 'status.cancel'
  }
};

export const FAMILY_RELATIONSHIP = [
  {
    id: 2,
    name: 'spouse'
  },
  {
    id: 1,
    name: 'children'
  }
];

export const FAMILY_RELATIONSHIP_MAPPER = {
  CHILDREN: 1,
  SPOUSE: 2
};

export const ADOSSPP_ROLES = {
  ACTIVE: 'ADH_En activité',
  INACTIVE: 'ADH_Membre non actif',
  FORMER: 'ADH_Ancien',
  COVERED: 'ADH_Couvert',
  COVERED_WITHOUT_RIGHTS: 'ADH_Couvert mais sans droits à la CSFC',
  BENEFACTOR: 'ADH_Membre bienfaiteur',
  ORPHAN: 'ADH_Orphelin',
  EMPLOYEE: 'ADH_Salarié',
  WIDOWER: 'ADH_Veuf'
};

export const SUBSCRIPTION_STATUS = {
  CLOSE_TO_EXPIRED: 'closeToExpired',
  EXPIRED: 'expired',
  OK: 'ok',
  OLD: 'old',
  RENEWED: 'renewed',
  UNPAYED: 'unPayed'
};

export const MILITARY_GRADE = [
  { value: 0, label: 'Aucun' },
  { value: 1, label: '1ère classe' },
  { value: 2, label: 'Adjudant-chef' },
  { value: 3, label: 'Adjudant' },
  { value: 4, label: 'Ancien' },
  { value: 5, label: 'Aspirant' },
  { value: 6, label: 'Caporal-chef' },
  { value: 7, label: 'Commandant' },
  { value: 8, label: 'Capitaine' },
  { value: 9, label: 'Colonel' },
  { value: 10, label: 'Caporal' },
  { value: 11, label: 'Général Brigade' },
  { value: 12, label: 'Lieutenant-colonel' },
  { value: 13, label: 'Lieutenant' },
  { value: 14, label: 'Major' },
  { value: 15, label: 'Médecin' },
  { value: 16, label: 'Sapeur' },
  { value: 17, label: 'Sergent-chef' },
  { value: 18, label: 'Sergent' },
  { value: 19, label: 'Sous-lieutenant' }
];

export const DAY_TIMES = {
  MORNING: 'morning',
  EVENING: 'evening',
  FULL_DAY: 'both'
};

export const COUNTRIES = [
  {
    id: 4, label: 'Afghanistan', value: 'Afghanistan', alpha2: 'af', alpha3: 'afg'
  },
  {
    id: 710, label: 'Afrique du Sud', value: 'Afrique du Sud', alpha2: 'za', alpha3: 'zaf'
  },
  {
    id: 8, label: 'Albanie', value: 'Albanie', alpha2: 'al', alpha3: 'alb'
  },
  {
    id: 12, label: 'Algérie', value: 'Algérie', alpha2: 'dz', alpha3: 'dza'
  },
  {
    id: 276, label: 'Allemagne', value: 'Allemagne', alpha2: 'de', alpha3: 'deu'
  },
  {
    id: 20, label: 'Andorre', value: 'Andorre', alpha2: 'ad', alpha3: 'and'
  },
  {
    id: 24, label: 'Angola', value: 'Angola', alpha2: 'ao', alpha3: 'ago'
  },
  {
    id: 28, label: 'Antigua-et-Barbuda', value: 'Antigua-et-Barbuda', alpha2: 'ag', alpha3: 'atg'
  },
  {
    id: 682, label: 'Arabie saoudite', value: 'Arabie saoudite', alpha2: 'sa', alpha3: 'sau'
  },
  {
    id: 32, label: 'Argentine', value: 'Argentine', alpha2: 'ar', alpha3: 'arg'
  },
  {
    id: 51, label: 'Arménie', value: 'Arménie', alpha2: 'am', alpha3: 'arm'
  },
  {
    id: 36, label: 'Australie', value: 'Australie', alpha2: 'au', alpha3: 'aus'
  },
  {
    id: 40, label: 'Autriche', value: 'Autriche', alpha2: 'at', alpha3: 'aut'
  },
  {
    id: 31, label: 'Azerbaïdjan', value: 'Azerbaïdjan', alpha2: 'az', alpha3: 'aze'
  },
  {
    id: 44, label: 'Bahamas', value: 'Bahamas', alpha2: 'bs', alpha3: 'bhs'
  },
  {
    id: 48, label: 'Bahreïn', value: 'Bahreïn', alpha2: 'bh', alpha3: 'bhr'
  },
  {
    id: 50, label: 'Bangladesh', value: 'Bangladesh', alpha2: 'bd', alpha3: 'bgd'
  },
  {
    id: 52, label: 'Barbade', value: 'Barbade', alpha2: 'bb', alpha3: 'brb'
  },
  {
    id: 112, label: 'Biélorussie', value: 'Biélorussie', alpha2: 'by', alpha3: 'blr'
  },
  {
    id: 56, label: 'Belgique', value: 'Belgique', alpha2: 'be', alpha3: 'bel'
  },
  {
    id: 84, label: 'Belize', value: 'Belize', alpha2: 'bz', alpha3: 'blz'
  },
  {
    id: 204, label: 'Bénin', value: 'Bénin', alpha2: 'bj', alpha3: 'ben'
  },
  {
    id: 64, label: 'Bhoutan', value: 'Bhoutan', alpha2: 'bt', alpha3: 'btn'
  },
  {
    id: 68, label: 'Bolivie', value: 'Bolivie', alpha2: 'bo', alpha3: 'bol'
  },
  {
    id: 70, label: 'Bosnie-Herzégovine', value: 'Bosnie-Herzégovine', alpha2: 'ba', alpha3: 'bih'
  },
  {
    id: 72, label: 'Botswana', value: 'Botswana', alpha2: 'bw', alpha3: 'bwa'
  },
  {
    id: 76, label: 'Brésil', value: 'Brésil', alpha2: 'br', alpha3: 'bra'
  },
  {
    id: 96, label: 'Brunei', value: 'Brunei', alpha2: 'bn', alpha3: 'brn'
  },
  {
    id: 100, label: 'Bulgarie', value: 'Bulgarie', alpha2: 'bg', alpha3: 'bgr'
  },
  {
    id: 854, label: 'Burkina Faso', value: 'Burkina Faso', alpha2: 'bf', alpha3: 'bfa'
  },
  {
    id: 108, label: 'Burundi', value: 'Burundi', alpha2: 'bi', alpha3: 'bdi'
  },
  {
    id: 116, label: 'Cambodge', value: 'Cambodge', alpha2: 'kh', alpha3: 'khm'
  },
  {
    id: 120, label: 'Cameroun', value: 'Cameroun', alpha2: 'cm', alpha3: 'cmr'
  },
  {
    id: 124, label: 'Canada', value: 'Canada', alpha2: 'ca', alpha3: 'can'
  },
  {
    id: 132, label: 'Cap-Vert', value: 'Cap-Vert', alpha2: 'cv', alpha3: 'cpv'
  },
  {
    id: 140, label: 'République centrafricaine', value: 'République centrafricaine', alpha2: 'cf', alpha3: 'caf'
  },
  {
    id: 152, label: 'Chili', value: 'Chili', alpha2: 'cl', alpha3: 'chl'
  },
  {
    id: 156, label: 'Chine', value: 'Chine', alpha2: 'cn', alpha3: 'chn'
  },
  {
    id: 196, label: 'Chypre', value: 'Chypre', alpha2: 'cy', alpha3: 'cyp'
  },
  {
    id: 170, label: 'Colombie', value: 'Colombie', alpha2: 'co', alpha3: 'col'
  },
  {
    id: 174, label: 'Comores', value: 'Comores', alpha2: 'km', alpha3: 'com'
  },
  {
    id: 178, label: 'République du Congo', value: 'République du Congo', alpha2: 'cg', alpha3: 'cog'
  },
  {
    id: 180, label: 'République démocratique du Congo', value: 'République démocratique du Congo', alpha2: 'cd', alpha3: 'cod'
  },
  {
    id: 410, label: 'Corée du Sud', value: 'Corée du Sud', alpha2: 'kr', alpha3: 'kor'
  },
  {
    id: 408, label: 'Corée du Nord', value: 'Corée du Nord', alpha2: 'kp', alpha3: 'prk'
  },
  {
    id: 188, label: 'Costa Rica', value: 'Costa Rica', alpha2: 'cr', alpha3: 'cri'
  },
  {
    id: 384, label: "Côte d'Ivoire", value: "Côte d'Ivoire", alpha2: 'ci', alpha3: 'civ'
  },
  {
    id: 191, label: 'Croatie', value: 'Croatie', alpha2: 'hr', alpha3: 'hrv'
  },
  {
    id: 192, label: 'Cuba', value: 'Cuba', alpha2: 'cu', alpha3: 'cub'
  },
  {
    id: 208, label: 'Danemark', value: 'Danemark', alpha2: 'dk', alpha3: 'dnk'
  },
  {
    id: 262, label: 'Djibouti', value: 'Djibouti', alpha2: 'dj', alpha3: 'dji'
  },
  {
    id: 214, label: 'République dominicaine', value: 'République dominicaine', alpha2: 'do', alpha3: 'dom'
  },
  {
    id: 212, label: 'Dominique', value: 'Dominique', alpha2: 'dm', alpha3: 'dma'
  },
  {
    id: 818, label: 'Égypte', value: 'Égypte', alpha2: 'eg', alpha3: 'egy'
  },
  {
    id: 222, label: 'Salvador', value: 'Salvador', alpha2: 'sv', alpha3: 'slv'
  },
  {
    id: 784, label: 'Émirats arabes unis', value: 'Émirats arabes unis', alpha2: 'ae', alpha3: 'are'
  },
  {
    id: 218, label: 'Équateur', value: 'Équateur', alpha2: 'ec', alpha3: 'ecu'
  },
  {
    id: 232, label: 'Érythrée', value: 'Érythrée', alpha2: 'er', alpha3: 'eri'
  },
  {
    id: 724, label: 'Espagne', value: 'Espagne', alpha2: 'es', alpha3: 'esp'
  },
  {
    id: 233, label: 'Estonie', value: 'Estonie', alpha2: 'ee', alpha3: 'est'
  },
  {
    id: 840, label: 'États-Unis', value: 'États-Unis', alpha2: 'us', alpha3: 'usa'
  },
  {
    id: 231, label: 'Éthiopie', value: 'Éthiopie', alpha2: 'et', alpha3: 'eth'
  },
  {
    id: 242, label: 'Fidji', value: 'Fidji', alpha2: 'fj', alpha3: 'fji'
  },
  {
    id: 246, label: 'Finlande', value: 'Finlande', alpha2: 'fi', alpha3: 'fin'
  },
  {
    id: 250, label: 'France', value: 'France', alpha2: 'fr', alpha3: 'fra'
  },
  {
    id: 266, label: 'Gabon', value: 'Gabon', alpha2: 'ga', alpha3: 'gab'
  },
  {
    id: 270, label: 'Gambie', value: 'Gambie', alpha2: 'gm', alpha3: 'gmb'
  },
  {
    id: 268, label: 'Géorgie', value: 'Géorgie', alpha2: 'ge', alpha3: 'geo'
  },
  {
    id: 288, label: 'Ghana', value: 'Ghana', alpha2: 'gh', alpha3: 'gha'
  },
  {
    id: 300, label: 'Grèce', value: 'Grèce', alpha2: 'gr', alpha3: 'grc'
  },
  {
    id: 308, label: 'Grenade', value: 'Grenade', alpha2: 'gd', alpha3: 'grd'
  },
  {
    id: 320, label: 'Guatemala', value: 'Guatemala', alpha2: 'gt', alpha3: 'gtm'
  },
  {
    id: 324, label: 'Guinée', value: 'Guinée', alpha2: 'gn', alpha3: 'gin'
  },
  {
    id: 624, label: 'Guinée-Bissau', value: 'Guinée-Bissau', alpha2: 'gw', alpha3: 'gnb'
  },
  {
    id: 226, label: 'Guinée équatoriale', value: 'Guinée équatoriale', alpha2: 'gq', alpha3: 'gnq'
  },
  {
    id: 328, label: 'Guyana', value: 'Guyana', alpha2: 'gy', alpha3: 'guy'
  },
  {
    id: 332, label: 'Haïti', value: 'Haïti', alpha2: 'ht', alpha3: 'hti'
  },
  {
    id: 340, label: 'Honduras', value: 'Honduras', alpha2: 'hn', alpha3: 'hnd'
  },
  {
    id: 348, label: 'Hongrie', value: 'Hongrie', alpha2: 'hu', alpha3: 'hun'
  },
  {
    id: 356, label: 'Inde', value: 'Inde', alpha2: 'in', alpha3: 'ind'
  },
  {
    id: 360, label: 'Indonésie', value: 'Indonésie', alpha2: 'id', alpha3: 'idn'
  },
  {
    id: 364, label: 'Iran', value: 'Iran', alpha2: 'ir', alpha3: 'irn'
  },
  {
    id: 368, label: 'Irak', value: 'Irak', alpha2: 'iq', alpha3: 'irq'
  },
  {
    id: 372, label: 'Irlande', value: 'Irlande', alpha2: 'ie', alpha3: 'irl'
  },
  {
    id: 352, label: 'Islande', value: 'Islande', alpha2: 'is', alpha3: 'isl'
  },
  {
    id: 376, label: 'Israël', value: 'Israël', alpha2: 'il', alpha3: 'isr'
  },
  {
    id: 380, label: 'Italie', value: 'Italie', alpha2: 'it', alpha3: 'ita'
  },
  {
    id: 388, label: 'Jamaïque', value: 'Jamaïque', alpha2: 'jm', alpha3: 'jam'
  },
  {
    id: 392, label: 'Japon', value: 'Japon', alpha2: 'jp', alpha3: 'jpn'
  },
  {
    id: 400, label: 'Jordanie', value: 'Jordanie', alpha2: 'jo', alpha3: 'jor'
  },
  {
    id: 398, label: 'Kazakhstan', value: 'Kazakhstan', alpha2: 'kz', alpha3: 'kaz'
  },
  {
    id: 404, label: 'Kenya', value: 'Kenya', alpha2: 'ke', alpha3: 'ken'
  },
  {
    id: 417, label: 'Kirghizistan', value: 'Kirghizistan', alpha2: 'kg', alpha3: 'kgz'
  },
  {
    id: 296, label: 'Kiribati', value: 'Kiribati', alpha2: 'ki', alpha3: 'kir'
  },
  {
    id: 414, label: 'Koweït', value: 'Koweït', alpha2: 'kw', alpha3: 'kwt'
  },
  {
    id: 418, label: 'Laos', value: 'Laos', alpha2: 'la', alpha3: 'lao'
  },
  {
    id: 426, label: 'Lesotho', value: 'Lesotho', alpha2: 'ls', alpha3: 'lso'
  },
  {
    id: 428, label: 'Lettonie', value: 'Lettonie', alpha2: 'lv', alpha3: 'lva'
  },
  {
    id: 422, label: 'Liban', value: 'Liban', alpha2: 'lb', alpha3: 'lbn'
  },
  {
    id: 430, label: 'Liberia', value: 'Liberia', alpha2: 'lr', alpha3: 'lbr'
  },
  {
    id: 434, label: 'Libye', value: 'Libye', alpha2: 'ly', alpha3: 'lby'
  },
  {
    id: 438, label: 'Liechtenstein', value: 'Liechtenstein', alpha2: 'li', alpha3: 'lie'
  },
  {
    id: 440, label: 'Lituanie', value: 'Lituanie', alpha2: 'lt', alpha3: 'ltu'
  },
  {
    id: 442, label: 'Luxembourg', value: 'Luxembourg', alpha2: 'lu', alpha3: 'lux'
  },
  {
    id: 807, label: 'Macédoine du Nord', value: 'Macédoine du Nord', alpha2: 'mk', alpha3: 'mkd'
  },
  {
    id: 450, label: 'Madagascar', value: 'Madagascar', alpha2: 'mg', alpha3: 'mdg'
  },
  {
    id: 458, label: 'Malaisie', value: 'Malaisie', alpha2: 'my', alpha3: 'mys'
  },
  {
    id: 454, label: 'Malawi', value: 'Malawi', alpha2: 'mw', alpha3: 'mwi'
  },
  {
    id: 462, label: 'Maldives', value: 'Maldives', alpha2: 'mv', alpha3: 'mdv'
  },
  {
    id: 466, label: 'Mali', value: 'Mali', alpha2: 'ml', alpha3: 'mli'
  },
  {
    id: 470, label: 'Malte', value: 'Malte', alpha2: 'mt', alpha3: 'mlt'
  },
  {
    id: 504, label: 'Maroc', value: 'Maroc', alpha2: 'ma', alpha3: 'mar'
  },
  {
    id: 584, label: 'Îles Marshall', value: 'Îles Marshall', alpha2: 'mh', alpha3: 'mhl'
  },
  {
    id: 480, label: 'Maurice', value: 'Maurice', alpha2: 'mu', alpha3: 'mus'
  },
  {
    id: 478, label: 'Mauritanie', value: 'Mauritanie', alpha2: 'mr', alpha3: 'mrt'
  },
  {
    id: 484, label: 'Mexique', value: 'Mexique', alpha2: 'mx', alpha3: 'mex'
  },
  {
    id: 583, label: 'États fédérés de Micronésie', value: 'États fédérés de Micronésie', alpha2: 'fm', alpha3: 'fsm'
  },
  {
    id: 498, label: 'Moldavie', value: 'Moldavie', alpha2: 'md', alpha3: 'mda'
  },
  {
    id: 492, label: 'Monaco', value: 'Monaco', alpha2: 'mc', alpha3: 'mco'
  },
  {
    id: 496, label: 'Mongolie', value: 'Mongolie', alpha2: 'mn', alpha3: 'mng'
  },
  {
    id: 499, label: 'Monténégro', value: 'Monténégro', alpha2: 'me', alpha3: 'mne'
  },
  {
    id: 508, label: 'Mozambique', value: 'Mozambique', alpha2: 'mz', alpha3: 'moz'
  },
  {
    id: 104, label: 'Birmanie', value: 'Birmanie', alpha2: 'mm', alpha3: 'mmr'
  },
  {
    id: 516, label: 'Namibie', value: 'Namibie', alpha2: 'na', alpha3: 'nam'
  },
  {
    id: 520, label: 'Nauru', value: 'Nauru', alpha2: 'nr', alpha3: 'nru'
  },
  {
    id: 524, label: 'Népal', value: 'Népal', alpha2: 'np', alpha3: 'npl'
  },
  {
    id: 558, label: 'Nicaragua', value: 'Nicaragua', alpha2: 'ni', alpha3: 'nic'
  },
  {
    id: 562, label: 'Niger', value: 'Niger', alpha2: 'ne', alpha3: 'ner'
  },
  {
    id: 566, label: 'Nigeria', value: 'Nigeria', alpha2: 'ng', alpha3: 'nga'
  },
  {
    id: 578, label: 'Norvège', value: 'Norvège', alpha2: 'no', alpha3: 'nor'
  },
  {
    id: 554, label: 'Nouvelle-Zélande', value: 'Nouvelle-Zélande', alpha2: 'nz', alpha3: 'nzl'
  },
  {
    id: 512, label: 'Oman', value: 'Oman', alpha2: 'om', alpha3: 'omn'
  },
  {
    id: 800, label: 'Ouganda', value: 'Ouganda', alpha2: 'ug', alpha3: 'uga'
  },
  {
    id: 860, label: 'Ouzbékistan', value: 'Ouzbékistan', alpha2: 'uz', alpha3: 'uzb'
  },
  {
    id: 586, label: 'Pakistan', value: 'Pakistan', alpha2: 'pk', alpha3: 'pak'
  },
  {
    id: 585, label: 'Palaos', value: 'Palaos', alpha2: 'pw', alpha3: 'plw'
  },
  {
    id: 591, label: 'Panama', value: 'Panama', alpha2: 'pa', alpha3: 'pan'
  },
  {
    id: 598, label: 'Papouasie-Nouvelle-Guinée', value: 'Papouasie-Nouvelle-Guinée', alpha2: 'pg', alpha3: 'png'
  },
  {
    id: 600, label: 'Paraguay', value: 'Paraguay', alpha2: 'py', alpha3: 'pry'
  },
  {
    id: 528, label: 'Pays-Bas', value: 'Pays-Bas', alpha2: 'nl', alpha3: 'nld'
  },
  {
    id: 604, label: 'Pérou', value: 'Pérou', alpha2: 'pe', alpha3: 'per'
  },
  {
    id: 608, label: 'Philippines', value: 'Philippines', alpha2: 'ph', alpha3: 'phl'
  },
  {
    id: 616, label: 'Pologne', value: 'Pologne', alpha2: 'pl', alpha3: 'pol'
  },
  {
    id: 620, label: 'Portugal', value: 'Portugal', alpha2: 'pt', alpha3: 'prt'
  },
  {
    id: 634, label: 'Qatar', value: 'Qatar', alpha2: 'qa', alpha3: 'qat'
  },
  {
    id: 642, label: 'Roumanie', value: 'Roumanie', alpha2: 'ro', alpha3: 'rou'
  },
  {
    id: 826, label: 'Royaume-Uni', value: 'Royaume-Uni', alpha2: 'gb', alpha3: 'gbr'
  },
  {
    id: 643, label: 'Russie', value: 'Russie', alpha2: 'ru', alpha3: 'rus'
  },
  {
    id: 646, label: 'Rwanda', value: 'Rwanda', alpha2: 'rw', alpha3: 'rwa'
  },
  {
    id: 659, label: 'Saint-Christophe-et-Niévès', value: 'Saint-Christophe-et-Niévès', alpha2: 'kn', alpha3: 'kna'
  },
  {
    id: 674, label: 'Saint-Marin', value: 'Saint-Marin', alpha2: 'sm', alpha3: 'smr'
  },
  {
    id: 670, label: 'Saint-Vincent-et-les-Grenadines', value: 'Saint-Vincent-et-les-Grenadines', alpha2: 'vc', alpha3: 'vct'
  },
  {
    id: 662, label: 'Sainte-Lucie', value: 'Sainte-Lucie', alpha2: 'lc', alpha3: 'lca'
  },
  {
    id: 90, label: 'Salomon', value: 'Salomon', alpha2: 'sb', alpha3: 'slb'
  },
  {
    id: 882, label: 'Samoa', value: 'Samoa', alpha2: 'ws', alpha3: 'wsm'
  },
  {
    id: 678, label: 'Sao Tomé-et-Principe', value: 'Sao Tomé-et-Principe', alpha2: 'st', alpha3: 'stp'
  },
  {
    id: 686, label: 'Sénégal', value: 'Sénégal', alpha2: 'sn', alpha3: 'sen'
  },
  {
    id: 688, label: 'Serbie', value: 'Serbie', alpha2: 'rs', alpha3: 'srb'
  },
  {
    id: 690, label: 'Seychelles', value: 'Seychelles', alpha2: 'sc', alpha3: 'syc'
  },
  {
    id: 694, label: 'Sierra Leone', value: 'Sierra Leone', alpha2: 'sl', alpha3: 'sle'
  },
  {
    id: 702, label: 'Singapour', value: 'Singapour', alpha2: 'sg', alpha3: 'sgp'
  },
  {
    id: 703, label: 'Slovaquie', value: 'Slovaquie', alpha2: 'sk', alpha3: 'svk'
  },
  {
    id: 705, label: 'Slovénie', value: 'Slovénie', alpha2: 'si', alpha3: 'svn'
  },
  {
    id: 706, label: 'Somalie', value: 'Somalie', alpha2: 'so', alpha3: 'som'
  },
  {
    id: 729, label: 'Soudan', value: 'Soudan', alpha2: 'sd', alpha3: 'sdn'
  },
  {
    id: 728, label: 'Soudan du Sud', value: 'Soudan du Sud', alpha2: 'ss', alpha3: 'ssd'
  },
  {
    id: 144, label: 'Sri Lanka', value: 'Sri Lanka', alpha2: 'lk', alpha3: 'lka'
  },
  {
    id: 752, label: 'Suède', value: 'Suède', alpha2: 'se', alpha3: 'swe'
  },
  {
    id: 756, label: 'Suisse', value: 'Suisse', alpha2: 'ch', alpha3: 'che'
  },
  {
    id: 740, label: 'Suriname', value: 'Suriname', alpha2: 'sr', alpha3: 'sur'
  },
  {
    id: 748, label: 'Eswatini', value: 'Eswatini', alpha2: 'sz', alpha3: 'swz'
  },
  {
    id: 760, label: 'Syrie', value: 'Syrie', alpha2: 'sy', alpha3: 'syr'
  },
  {
    id: 762, label: 'Tadjikistan', value: 'Tadjikistan', alpha2: 'tj', alpha3: 'tjk'
  },
  {
    id: 834, label: 'Tanzanie', value: 'Tanzanie', alpha2: 'tz', alpha3: 'tza'
  },
  {
    id: 148, label: 'Tchad', value: 'Tchad', alpha2: 'td', alpha3: 'tcd'
  },
  {
    id: 203, label: 'Tchéquie', value: 'Tchéquie', alpha2: 'cz', alpha3: 'cze'
  },
  {
    id: 764, label: 'Thaïlande', value: 'Thaïlande', alpha2: 'th', alpha3: 'tha'
  },
  {
    id: 626, label: 'Timor oriental', value: 'Timor oriental', alpha2: 'tl', alpha3: 'tls'
  },
  {
    id: 768, label: 'Togo', value: 'Togo', alpha2: 'tg', alpha3: 'tgo'
  },
  {
    id: 776, label: 'Tonga', value: 'Tonga', alpha2: 'to', alpha3: 'ton'
  },
  {
    id: 780, label: 'Trinité-et-Tobago', value: 'Trinité-et-Tobago', alpha2: 'tt', alpha3: 'tto'
  },
  {
    id: 788, label: 'Tunisie', value: 'Tunisie', alpha2: 'tn', alpha3: 'tun'
  },
  {
    id: 795, label: 'Turkménistan', value: 'Turkménistan', alpha2: 'tm', alpha3: 'tkm'
  },
  {
    id: 792, label: 'Turquie', value: 'Turquie', alpha2: 'tr', alpha3: 'tur'
  },
  {
    id: 798, label: 'Tuvalu', value: 'Tuvalu', alpha2: 'tv', alpha3: 'tuv'
  },
  {
    id: 804, label: 'Ukraine', value: 'Ukraine', alpha2: 'ua', alpha3: 'ukr'
  },
  {
    id: 858, label: 'Uruguay', value: 'Uruguay', alpha2: 'uy', alpha3: 'ury'
  },
  {
    id: 548, label: 'Vanuatu', value: 'Vanuatu', alpha2: 'vu', alpha3: 'vut'
  },
  {
    id: 862, label: 'Venezuela', value: 'Venezuela', alpha2: 've', alpha3: 'ven'
  },
  {
    id: 704, label: 'Viêt Nam', value: 'Viêt Nam', alpha2: 'vn', alpha3: 'vnm'
  },
  {
    id: 887, label: 'Yémen', value: 'Yémen', alpha2: 'ye', alpha3: 'yem'
  },
  {
    id: 894, label: 'Zambie', value: 'Zambie', alpha2: 'zm', alpha3: 'zmb'
  },
  {
    id: 716, label: 'Zimbabwe', value: 'Zimbabwe', alpha2: 'zw', alpha3: 'zwe'
  }
];

export const ID_FRANCE_ISO_3166 = 250;

export const FRANCE_REVUE_CODE = 'ALLO18';
export const WORLDWIDE_REVUE_CODE = 'ALLO18ETR';

export const RESUBSCRIPTION_DELAY = 60;

export const BANNER_COLORS = [
  { value: 'error', color: '--primary-color' },
  { value: 'warning', color: '--tertiary-color' }
];

export const SPECIFIC_ROUTE_201 = [
  'https://adosspp-allowance-api.dev.intrassoc.com/api/AllowanceApplication'
];

export const CIVILITIES = ['mr', 'mrs'];

export const MAGAZINE = [
  {
    id: 0, label: 'Magazine ALLO 18', value: 'ALLO18'
  }
];

export const RGPD_LINK = 'https://www.adosspp.com/les-mesures-rgpd/';