import React, { useCallback, useEffect, useState } from 'react';
import {
  FormCheckboxContainer, FormSectionTitle, TextLine
} from 'components';
import styled from 'styled-components';
import shortid from 'shortid';
import {
  Button, Checkbox, FormControlLabel, Grid,
  Step, StepButton, StepContent, Stepper, TextField, Typography
} from '@material-ui/core';
import {
  faCalendarAlt, faInfoSquare, faUsers
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils';
import { ALLOWANCE_LEISURE_ADVANTAGE_TYPE, ALLOWANCE_LEISURE_TARIFF_TYPE } from 'utils/constants';
import { useStores } from 'hooks';
import { AllowanceLeisureContainer } from 'components/_commons';
import BookingByHalfDay from '../BookingByHalfDay/BookingByHalfDay';
import BookingByWeek from '../BookingByWeek/BookingByWeek';
import BookingByNight from '../BookingByNight/BookingByNight';

const RightHolderContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border: solid var(--grey) 2px;
  padding: 10px;
  text-align: center;

  &[data-selected="true"] {
    border-color: var(--secondary-color-light);
    box-shadow: 0 0 6px var(--secondary-color-light);
  }

  &[data-disabled="true"] {
    border-color: var(--grey-dark);
    background-color: var(--grey);
  }
`;
const InternOption = ({
  allowance,
  selectedRightHolders,
  setSelectedRightHolders,
  add, formState,
  number, checkbox,
  select, dateRange,
  setDateRange,
  userAllowanceApplicationDetail,
  isFormDisabled
}) => {
  const { userStore } = useStores();
  const { userRightHolder, userConnected } = userStore;

  const [activeStep, setActiveStep] = useState(0);
  const [maxNumberPlaces, setMaxNumberPlaces] = useState(1);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    allowance.interEstablishments.forEach(interEstablishment => {
      if (interEstablishment.id === formState.values.interEstablishmentAllowanceId) {
        setMaxNumberPlaces(interEstablishment.unitCapacity);
      }
    });
  }, [formState.values.interEstablishmentAllowanceId, allowance]);

  useEffect(() => {
    if (userAllowanceApplicationDetail) {
      setActiveStep(1);
      formState.setField('certifyData', true);
      formState.setField('certifyPersonalData', true);
    }
  }, [userAllowanceApplicationDetail, formState]);

  const setPrice = useCallback(price => {
    if (allowance.advantageTypes[0].id === ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.code) {
      formState.setField('price', 0);
    } else {
      formState.setField('price', price);
    }
  }, [formState, allowance.advantageTypes]);

  const setInterEstablishmentID = useCallback(id => {
    formState.setField('interEstablishmentAllowanceId', id);
    formState.setField('interEstablishmentAllowance', allowance.interEstablishments.find(i => i.id === id));
  }, [formState, allowance.interEstablishments]);

  const handleSelectRightHolder = rH => {
    if (!isFormDisabled) {
      if (selectedRightHolders.includes(rH.id)) {
        setSelectedRightHolders(selectedRightHolders.filter(item => item !== rH.id));
      } else if (selectedRightHolders.length < maxNumberPlaces - 1) {
        add(rH.id);
      }
    }
  };

  const isRightHolderDisabled = rightHolder => (
    !selectedRightHolders.includes(rightHolder.id) && selectedRightHolders.length >= maxNumberPlaces - 1
  );

  const resetData = () => {
    if (!userAllowanceApplicationDetail) {
      formState.setField('certifyPersonalData', false);
      formState.setField('certifyData', false);
      formState.setField('isRightHolders', false);
      setSelectedRightHolders([]);
    }
  };

  const renderSwitchInternType = (interType) => {
    if (interType === ALLOWANCE_LEISURE_TARIFF_TYPE.BY_HALF_DAY.code) {
      return (
        <BookingByHalfDay
          allowance={allowance}
          dateRange={dateRange}
          formState={formState}
          select={select}
          setDateRange={setDateRange}
          setInterEstablishmentID={setInterEstablishmentID}
          setPrice={setPrice}
          setIsError={setIsError}
          isError={isError}
          userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          userTariffRange={userConnected.trancheTarifaire}
        />
      );
    }

    if (interType === ALLOWANCE_LEISURE_TARIFF_TYPE.BY_NIGHT.code) {
      return (
        <BookingByNight
          allowance={allowance}
          dateRange={dateRange}
          formState={formState}
          select={select}
          setDateRange={setDateRange}
          setInterEstablishmentID={setInterEstablishmentID}
          price={formState.values.price}
          setPrice={setPrice}
          setIsError={setIsError}
          isError={isError}
          userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          userTariffRange={userConnected.trancheTarifaire}
        />
      );
    }

    return (
      <BookingByWeek
        allowance={allowance}
        dateRange={dateRange}
        price={formState.values.price}
        setDateRange={setDateRange}
        setInterEstablishmentID={setInterEstablishmentID}
        setPrice={setPrice}
        setTTError={setIsError}
        tTError={isError}
        userAllowanceApplicationDetail={userAllowanceApplicationDetail}
        userTariffRange={userConnected.trancheTarifaire}
      />
    );
  };

  return (
    <AllowanceLeisureContainer>
      <TextLine className="bold">Pour procéder à la réservation, réaliser dans l’ordre les étapes ci-dessous : </TextLine>

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepButton
            icon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
            onClick={() => {
              setActiveStep(0);
              resetData();
            }}
          >
            1. D’abord, séléctionnez l&apos;une des offres de l&apos;ADOSSPP pour procéder à la demande de réservation
          </StepButton>

          <StepContent>
            {renderSwitchInternType(allowance.tariffType.id)}
            <br />
            <Grid item>
              <Button color="secondary" disabled={!dateRange || isError} onClick={() => setActiveStep(1)}>
                {userAllowanceApplicationDetail ? translate('button.next') : translate('button.book') }
              </Button>
            </Grid>
          </StepContent>
        </Step>
        <Step>
          <StepButton
            icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
            onClick={() => setActiveStep(1)}
          >
            2. Enfin, saisissez les informations des occupants pour finaliser votre demande.
          </StepButton>

          <StepContent>
            <Grid container direction="column" spacing={2}>
              {userRightHolder.length > 0 && (
                <>
                  <Grid item sm={6} xs={12}>
                    <FormSectionTitle title={translate('common.people')} />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          disabled={isFormDisabled || maxNumberPlaces <= 1}
                          name="isRightHolders"
                          {...checkbox('isRightHolders')}
                        />
                      )}
                      label={translate('forms.isRightHolders')}
                    />
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    {formState.values.isRightHolders && userRightHolder.map(rH => (
                      <Grid item key={shortid.generate()}>
                        <RightHolderContainer
                          data-disabled={isFormDisabled || isRightHolderDisabled(rH)}
                          data-selected={selectedRightHolders.includes(rH.id)}
                          onClick={() => handleSelectRightHolder(rH)}
                        >
                          {`${rH.firstName} ${rH.lastName}`}
                        </RightHolderContainer>
                      </Grid>
                    ))}
                  </Grid>
                  {(maxNumberPlaces === 1
                  || (selectedRightHolders.length === maxNumberPlaces - 1
                    && selectedRightHolders.length !== userRightHolder.length))
                  && (
                    <Grid item>
                      <Typography color="error" gutterBottom variant="caption">
                        La capacité sélectionnée ne permet pas de choisir plus de personnes pour cette prestation
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
              <Grid item>
                <FormSectionTitle icon={faInfoSquare} title={translate('common.moreInfos')} />
                <TextLine>
                  Afin de mieux répondre à votre demande de subvention et besoins,
                  n&apos;hésitez pas à ajouter des informations complémentaires à votre dossier.
                </TextLine>
                <Typography>Remarques :</Typography>
                <TextField
                  fullWidth
                  id="memberComment"
                  label={translate('common.observations')}
                  multiline
                  name="memberComment"
                  rows={3}
                  variant="outlined"
                  {...number('memberComment')}
                />
              </Grid>
              <Grid item>
                <FormCheckboxContainer>
                  <FormControlLabel
                    control={<Checkbox required />}
                    label={translate('forms.acceptSalesConditions')}
                    {...checkbox('certifyData')}
                  />
                  <FormControlLabel
                    control={<Checkbox required />}
                    label={translate('forms.certifyPersonalData')}
                    {...checkbox('certifyPersonalData')}
                  />
                </FormCheckboxContainer>
              </Grid>
            </Grid>
          </StepContent>
        </Step>
      </Stepper>
    </AllowanceLeisureContainer>
  );
};

export default InternOption;